import React from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';

import useStyles from './KillSwitch.styles';

const KillSwitchView = ({
  onSubmit = () => {},
  error = null,
  isPending = false,
  isSettled = false,
}) => {
  const classes = useStyles();

  return (
    <Card>
      <div className={classes.content}>
        <Typography
          variant="h6"
          paragraph
        >
          { isPending && !error && 'Executing '}
          Kill Switch
          { isSettled && !error && ' Completed Successfully'}
          { error && ' Has Errored'}
        </Typography>
        {isPending && error == null && (
          <CircularProgress
            className={classes.progress}
            size={100}
          />
        )}
        {isSettled && !error && (
          <>
            <div className={classes.doneIcon}>
              <i className="material-icons">done</i>
            </div>
          </>
        )}
        <Typography
          variant="body1"
          paragraph
          color={error ? 'error' : 'textPrimary'}
          className={classes.message}
        >
          {error}
          { !isPending && !isSettled && !error && 'Please confirm you wish to lock the user\'s account.'}
          {!error && isPending && 'Please do not close this tab.'}
          {!error && isSettled && 'You may now safely close this tab.'}
        </Typography>
        { !isPending && !isSettled && !error && (
          <Button
            variant="contained"
            onClick={onSubmit}
            color="primary"
          >
            Confirm
          </Button>
        )}
      </div>
    </Card>
  );
};

export default KillSwitchView;
