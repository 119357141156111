import React, {
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
} from 'react';

import { useForm } from 'react-hook-form';
import FadeIn from 'react-fade-in';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import useStyles from './PasswordReset.styles';

const PasswordReset = ({
  onSubmit,
  isSubmitting = false,
  defaultValues,
  error: externalError = null,
}) => {
  const classes: any = useStyles();

  const ua = navigator.userAgent;
  const isPanGlobalProtect = ua.toLowerCase().includes('globalprotect');

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.only('xs'));
  const textFieldMargin = mobile ? 'dense' : 'normal';
  const textFieldSize = mobile ? 'small' : 'medium';
  const buttonSize = textFieldSize;

  const [hidePasssword, setHidePasssword] = useState(true);

  const {
    handleSubmit,
    register,
    errors,
    setError,
    formState,
    getValues,
  } = useForm({ defaultValues });

  const error = errors.password?.message;

  useEffect(() => {
    if (!isSubmitting) {
      setError('password', {
        type: 'server',
        message: externalError,
        shouldFocus: true,
      });
    }
  }, [externalError, isSubmitting]);

  const passwordResetRef: any = useRef();
  useLayoutEffect(() => passwordResetRef.current.focus(), []);

  return (
    <form
      className={classes.formContainer}
      autoComplete="off"
      // @ts-ignore
      onSubmit={handleSubmit((values) => onSubmit({ values, setError }))}
    >
      <input className={classes.passwordAutocompleteTrap} id="_autocompleteTrap" type="password" />

      <Card className={classes.card}>
        <FadeIn delay={100} transitionDuration={500}>
          <Typography variant="h1" className={classes.title}>
            Password Reset Required
          </Typography>
          <TextField
            name="password"
            label="Enter your new password"
            variant="outlined"
            type={hidePasssword ? 'password' : 'text'}
            autoComplete="off"
            autoFocus
            fullWidth
            margin={textFieldMargin}
            size={textFieldSize}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: isPanGlobalProtect ? undefined : (
                <InputAdornment position="end">
                  <i
                    className={`material-icons ${classes.hidePassword}`}
                    onClick={() => setHidePasssword(!hidePasssword)}
                    onKeyPress={(e) => e.keyCode === 13 && setHidePasssword(!hidePasssword)}
                    role="button"
                    tabIndex={-1}
                  >
                    {hidePasssword ? 'visibility' : 'visibility_off'}
                  </i>
                </InputAdornment>
              ),
            }}
            inputRef={(e) => {
              passwordResetRef.current = e;
              register(e, {
                required: 'Required',
              });
            }}
            error={error != null}
            helperText={error?.split('\n').map((text) => <p>{text}</p>)}
          />
          <TextField
            name="passwordConfirmation"
            label="Confirm your new password"
            variant="outlined"
            type={hidePasssword ? 'password' : 'text'}
            autoComplete="off"
            autoFocus
            fullWidth
            margin={textFieldMargin}
            size={textFieldSize}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: isPanGlobalProtect ? undefined : (
                <InputAdornment position="end">
                  <i
                    className={`material-icons ${classes.hidePassword}`}
                    onClick={() => setHidePasssword(!hidePasssword)}
                    onKeyPress={(e) => e.keyCode === 13 && setHidePasssword(!hidePasssword)}
                    role="button"
                    tabIndex={-1}
                  >
                    {hidePasssword ? 'visibility' : 'visibility_off'}
                  </i>
                </InputAdornment>
              ),
            }}
            inputRef={register({
              required: 'Required',
              validate: {
                matchesPassword: (val) => val === getValues().password,
              },
            })}
            error={errors.passwordConfirmation != null}
            helperText={
              errors.passwordConfirmation && (
                errors.passwordConfirmation.message || 'Passwords do not match'
              )
            }
          />
          <div className={classes.formButtons}>
            <Button
              color="primary"
              type="submit"
              variant="contained"
              size={buttonSize}
              disabled={isSubmitting || formState.isSubmitting}
            >
              RESET PASSWORD
            </Button>
          </div>
        </FadeIn>
      </Card>
    </form>
  );
};

export default PasswordReset;
