import { makeStyles } from '@mui/styles';

export const loginAndPasswordCommonStyles = (theme) => ({
  formContainer: {
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(5),

    '& ::-ms-clear': {
      display: 'none !important',
    },
    '& ::-ms-reveal': {
      display: 'none !important',
    },
  },
  title: {
    textAlign: 'center',
    marginBottom: theme.spacing(3),
  },
  formButtons: {
    display: 'flex',
    flex: 0,
    justifyContent: 'space-between',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(5),
  },
});

// @ts-ignore
export default makeStyles((theme) => ({
  ...loginAndPasswordCommonStyles(theme),

  credentialsInstructions: {
    marginTop: theme.spacing(3),
  },
  actions: {
    display: 'flex',
    flex: 0,
    justifyContent: 'space-between',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(5),
  },
  loginButton: {
    marginTop: theme.spacing(2),
  },
}));
