import React from 'react';

import { Link } from 'react-router-dom';

import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import useStyles from './RecoveryCodeSetup.styles';
import { RecoveryCodeBatchView } from '@lastwall/credentials';

const RecoveryCodeSetupView = ({
  recoveryCodes = [],
  error,
}) => {
  const classes = useStyles();

  if (error) {
    return (
      <Card className={classes.card}>
        <Typography variant="body1" color="error" paragraph>
          There was a problem generating recovery codes. Please try again.
        </Typography>
        {
          // @ts-ignore
          !window.isProduction && (
            <Typography variant="body2" color="error" paragraph>
              { `[DEV MODE ONLY] Error: ${error}` }
            </Typography>
          )
        }
      </Card>
    );
  }

  return (
    <Card className={classes.card}>
      <Typography variant="h2" paragraph>
        Recovery Codes
      </Typography>
      <RecoveryCodeBatchView
        backupCodes={recoveryCodes}
      >
        <Button
          color="primary"
          size="small"
          type="submit"
          variant="contained"
          component={Link}
          to="./two-factor-setup"
        >
          Done
        </Button>
      </RecoveryCodeBatchView>
    </Card>
  );
};

export default RecoveryCodeSetupView;
