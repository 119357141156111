import React, { useEffect } from 'react';
import { useStateMachine } from 'little-state-machine';
import {
  useHistory,
  useParams,
} from 'react-router-dom';

import { useFetchGraphQL } from '../../shared/apiFetchOptions';
import { updateStage } from '../../store/stage/stageStore';
import { onAuthorizedToLogin } from '../../shared/hooks/useNavigateToNext';

import TwoFactorSetupView from './TwoFactorSetup.view';
import stageQueryFragment from '../../store/stage/stageQuery.fragment';

const TwoFactorSetupPage = () => {
  const history = useHistory();
  const { authSessionSlug } = useParams();

  const { state, actions } = useStateMachine({
    updateStage,
  }) as any;
  const {
    nextPolicyRequirements,
    credentialSummaries,
    forceOnboarding,
  } = state.stage;

  const createStagesMutation = `
    mutation {
      createStages {
        ${stageQueryFragment}
      }
    }
  `;

  const deleteCredentialMutation = `
  mutation($input: DeleteCredentialInput!) {
    deleteCredential(input: $input) {
      ${stageQueryFragment}
    }
  }
`;

  const createStages: any = useFetchGraphQL(async ({ fetchGraphQL }) => {
    const data = await fetchGraphQL({
      variables: {},
      state,
      query: createStagesMutation,
    });
    actions.updateStage(data.createStages);

    return data.createStages;
  }, [state]);

  useEffect(createStages.run, []);

  const deleteCredential: any = useFetchGraphQL(async ({ args, fetchGraphQL }) => {
    const { id, type } = args[0].credential;

    const data = await fetchGraphQL({
      variables: {
        input: {
          id,
          type,
        },
      },
      state,
      query: deleteCredentialMutation,
    });
    actions.updateStage(data.deleteCredential);

    return data.createStages;
  }, [state]);

  const authorizedToLogin = state.stage.state === 'AUTHORIZED_TO_LOGIN';

  if (!createStages.isSettled) {
    return <div />;
  }

  return (
    <TwoFactorSetupView {...{
      error: createStages.error?.message ?? deleteCredential.error?.message,
      nextPolicyRequirements,
      credentialSummaries,
      forceOnboarding,
      authorizedToLogin,
      deleteCredential: deleteCredential.run,
      login: () => onAuthorizedToLogin({
        history,
        authSessionSlug,
      }),
      logoURL: state.orgProfile.logoURL,
    }}
    />
  );
};

export default TwoFactorSetupPage;
