import React from 'react';
import { Link } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import useStyles from './WebauthnSetup.styles';
import CardWithLogo from '../../shared/card/CardWithLogo';

const WebauthnSetupView = ({
  logoURL,
  error = null,
  webAuthnState = 'getUserConsent',
  onGetCredentialOptions = () => {},
  onFinished = () => {},
  onFinishError = null,
}) => {
  const classes = useStyles();

  const isSupported = (typeof window.PublicKeyCredential === 'function');

  const {
    handleSubmit,
    register,
    errors,
  } = useForm();

  return (
    <form
      // @ts-ignore
      onSubmit={handleSubmit((values) => onFinished({ values }))}
    >
      <CardWithLogo logoURL={logoURL}>
        <div className={classes.cardContent}>
          { !isSupported && (
            <>
              <Typography variant="h2" paragraph>
                Web Authn Authentication
              </Typography>
              <Typography variant="body1" paragraph color="error">
                Web Authn is not available on your platform. Please use a different credential.
              </Typography>
            </>
          )}
          { isSupported && (
            <>
              { (webAuthnState === 'getUserConsent') && (
                <>
                  <Typography variant="body1" paragraph>
                    Plug in your WebAuthn key before pressing
                    { error ? ' retry' : ' continue'}
                  </Typography>
                  { error && (
                    <Typography
                      variant="body1"
                      paragraph
                      color="error"
                      className={classes.message}
                    >
                      {error}
                    </Typography>
                  )}

                  <Button
                    color="primary"
                    onClick={onGetCredentialOptions}
                    variant="contained"
                  >
                    { error ? 'Retry' : 'Continue' }
                  </Button>
                </>
              )}
              { (webAuthnState === 'getAttestation') && (
                <>
                  <Typography variant="h2" paragraph>
                    Awaiting your authorization
                  </Typography>
                  <Typography
                    variant="body1"
                    paragraph
                    color="textPrimary"
                    className={classes.message}
                  >
                    Please tap or otherwise authorize your Web Authn key
                  </Typography>
                </>
              )}
              { (webAuthnState === 'getNickname') && (
                <>
                  <Typography variant="h2" paragraph>
                    Web Authn Key Setup
                  </Typography>
                  <TextField
                    name="nickname"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    placeholder="eg. Yubikey/TouchID"
                    inputRef={register({
                      required: 'Required',
                    })}
                    error={errors.nickname != null}
                    helperText={errors.nickname?.message}
                  />
                  <Typography variant="body1" paragraph>
                    Add a descriptive name for the key and press
                    { onFinishError ? ' retry' : ' continue'}
                  </Typography>
                  { onFinishError && (
                    <Typography
                      variant="body1"
                      paragraph
                      color="error"
                      className={classes.message}
                    >
                      {onFinishError}
                    </Typography>
                  )}

                  <Button
                    color="primary"
                    type="submit"
                    variant="contained"
                  >
                    { onFinishError ? 'Retry' : 'Continue' }
                  </Button>
                </>
              )}
            </>
          )}
        </div>
      </CardWithLogo>
      <Button
        className={classes.startOverButton}
        variant="contained"
        component={Link}
        to="./two-factor-setup">
        Start Over
      </Button>
    </form>
  );
};

export default WebauthnSetupView;
