import { makeStyles } from '@mui/styles';
import { loginAndPasswordCommonStyles } from '../login/Login.styles';

// @ts-ignore
export default makeStyles((theme) => ({
  ...loginAndPasswordCommonStyles(theme),

  card: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    width: 400,
    [theme.breakpoints.only('xs')]: {
      // 360px is the smallest viewport width of popular Android phones atm
      width: 360,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2),
    },
    textAlign: 'center',
  },
  hidePassword: {
    cursor: 'pointer',
  },
  passwordAutocompleteTrap: {
    display: 'none',
  },
}));
