import { makeStyles } from '@mui/styles';

const isIE = (window.document as any).documentMode;

let ieHacks: any = {};

if (isIE) {
  ieHacks = {
    height: '100vh',
  };
}

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.only('xs')]: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    backgroundSize: 'cover',
    ...ieHacks,
  },
}));
