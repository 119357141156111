import React from 'react';
import { useStateMachine } from 'little-state-machine';

import useStyles from './Centered.styles';

const Centered = ({
  children,
}) => {
  const classes = useStyles();

  const { state } = useStateMachine();
  // @ts-ignore
  const { backgroundURL } = state?.orgProfile;

  return (
    <div
      className={classes.root}
      style={{ backgroundImage: backgroundURL && `url(${backgroundURL})` }}
    >
      { children }
    </div>
  );
};

export default Centered;
