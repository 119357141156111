import React from 'react';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import ReactMarkdown from 'react-markdown';

import useStyles from './TermsAndConditions.styles';
import CardWithLogo from '../../shared/card/CardWithLogo';

const TermsAndConditionsView = ({
  logoURL,
  acceptTerms = () => {},
  termsAndConditionsMarkdown,
  termsAndConditionsAcceptButtonText,
}) => {
  const classes: any = useStyles();

  return (
    <CardWithLogo logoURL={logoURL}>
      <ReactMarkdown
        className={classes.termsText}
        renderers={{
          paragraph: Typography,
        }}
      >
        {termsAndConditionsMarkdown}
      </ReactMarkdown>
      <Button
        className={classes.acceptTermsButton}
        color="primary"
        variant="contained"
        onClick={acceptTerms}
      >
        {termsAndConditionsAcceptButtonText}
      </Button>
    </CardWithLogo>
  );
};

export default TermsAndConditionsView;
