import React, { useState, useEffect } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import useStyles from './Friction.styles';
import CardWithLogo from '../../shared/card/CardWithLogo';

const FrictionView = ({
  logoURL,
  type,
  message,
  timeoutMillis,
}) => {
  const classes = useStyles();

  const [startedAt] = useState(() => Date.now());
  const [value, setValue] = useState(0);

  useEffect(() => {
    const tickAnimation = () => {
      setValue((Date.now() - startedAt) / (timeoutMillis));
    };

    // The css animates over 300ms so we give it a new position to animate to every *almost* 300ms.
    // The almost (-10ms) is so that the circular progress does not come to a hault if the animation
    // interval is a little later then expected.
    const animationInterval = setInterval(tickAnimation, 290);

    return () => clearInterval(animationInterval);
  }, []);

  return (
    <CardWithLogo logoURL={logoURL}>
      <div className={classes.friction}>
        { type === 'timeout' && (
          <CircularProgress
            className={classes.frictionProgress}
            value={Math.min(value * 100, 100)}
            size={100}
            variant="determinate"
          />
        )}
        <Typography variant="body2" paragraph className={classes.userFrictionMessage}>
          {message}
        </Typography>
      </div>
    </CardWithLogo>
  );
};

export default FrictionView;
