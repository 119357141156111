import React from 'react';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import useStyles from './TwoFactorWebAuthn.styles';
import TwoFactorSelector from '../two-factor-selector/TwoFactorSelector';

const TwoFactorWebAuthnView = ({
  factors,
  pending = false,
  error = null,
  onSubmit = () => {},
}) => {
  const classes = useStyles();

  const isSupported = true;

  return (
    <div className={classes.cardContent}>
      { !isSupported && (
        <>
          <Typography variant="h2" paragraph>
            Web Authn Authentication
          </Typography>
          <Typography variant="body1" paragraph color="error">
            Web Authn is not available on your platform. Please use a different credential.
          </Typography>
        </>
      )}
      { isSupported && (
        <>
          { pending && (
            <>
              <Typography variant="h2" paragraph>
                Awaiting your authorization
              </Typography>
              <Typography
                variant="body1"
                paragraph
                color="textPrimary"
                className={classes.message}
              >
                Please tap or otherwise authorize your Web Authn key
              </Typography>
            </>
          )}
          { !pending && (
            <>
              <Typography variant="h2" paragraph>
                Web Authn Authentication
              </Typography>
              <Typography variant="body1" paragraph>
                Please plug in your WebAuthn key before pressing retry
              </Typography>
              { error && (
                <Typography
                  variant="body1"
                  paragraph
                  color="error"
                  className={classes.message}
                >
                  {error}
                </Typography>
              )}

              <Button
                color="primary"
                variant="contained"
                onClick={onSubmit}
              >
                Retry
              </Button>
            </>
          )}
        </>
      )}
      <TwoFactorSelector factors={factors.filter((x) => x.type !== 'WEBAUTHN')} />
    </div>
  );
};

export default TwoFactorWebAuthnView;
