import React, { useEffect } from 'react';

import { useStateMachine } from 'little-state-machine';

import { useFetchGraphQL } from '../../shared/apiFetchOptions';
import useNavigateToNext from '../../shared/hooks/useNavigateToNext';
import stageQueryFragment from '../../store/stage/stageQuery.fragment';

import FrictionView from './Friction.view';

const FrictionPage = () => {
  const navigateToNext = useNavigateToNext();
  const { state } = useStateMachine() as any;
  const { friction } = state.orgProfile;

  const timeoutMillis = friction.timeout * 1000;

  const completeFrictionMutation = `
    mutation {
      completeFriction {
        ${stageQueryFragment}
      }
    }
  `;

  const completeFriction = useFetchGraphQL(async ({ fetchGraphQL }) => {
    const data = await fetchGraphQL({
      state,
      query: completeFrictionMutation,
    });

    navigateToNext(data.completeFriction);
  }, [state]);

  useEffect(() => {
    if (friction.type === 'timeout') {
      const completionTimeout = setTimeout(() => completeFriction.run(), timeoutMillis);

      return () => clearTimeout(completionTimeout);
    }
    return null;
  }, []);

  return (
    <FrictionView
      {...{
        logoURL: state.orgProfile.logoURL,
        type: friction.type,
        message: friction.message,
        timeoutMillis,
      }}
    />
  );
};

export default FrictionPage;
