import React, {
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
} from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

// import MUILink from '@mui/material/Link'
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Icon from '@mui/material/Icon';
import TextField from '@mui/material/TextField';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';

import useStyles from './TOTPSetup.styles';

// @ts-ignore
import appStoreBadge from '../../../assets/img/appstorebadge.svg';
// @ts-ignore
import playStoreBadge from '../../../assets/img/playstorebadge.png';

export interface TOTPSetupProps {
  onSubmit?: (values: any) => Promise<void>
  onBackClick?: () => void
  isSubmitting?: boolean
  activeStep?: number
  qrCodeImage?: string
  otpAuthURL?: string
  base32?: string,
  createTOTPCodeError?: string
  verifyTOTPCodeError?: string
}

const isIOS = () => (
  [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod',
  ].includes(navigator.platform)
  // iPad on iOS 13 detection
  || (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
);

const TOTPSetupView = ({
  onSubmit = async () => { },
  onBackClick = () => { },
  isSubmitting = false,
  activeStep = 0,
  qrCodeImage,
  otpAuthURL,
  base32,
  createTOTPCodeError,
  verifyTOTPCodeError,
}: TOTPSetupProps) => {
  const classes = useStyles();

  const userAgent = navigator.userAgent.toLowerCase();
  const isAndroid = userAgent.indexOf('android') > -1;
  const isMobile = isAndroid || isIOS();

  const [isRevealed, setReveal] = useState(false);

  const {
    handleSubmit,
    register,
    errors,
    setError,
    formState,
  } = useForm();

  useEffect(() => {
    if (!isSubmitting && verifyTOTPCodeError) {
      setError('code', {
        type: 'server',
        message: verifyTOTPCodeError,
        shouldFocus: true,
      });
    }
  }, [verifyTOTPCodeError, isSubmitting]);

  const codeRef: any = useRef();

  useLayoutEffect(() => {
    if (activeStep === 1) {
      codeRef.current?.focus();
    }
  }, [activeStep]);

  if (createTOTPCodeError) {
    return (
      <Card className={classes.card}>
        <Typography variant="body1" color="error" paragraph>
          {createTOTPCodeError}
        </Typography>
      </Card>
    );
  }

  return (
    <form
      // @ts-ignore
      onSubmit={handleSubmit((values) => onSubmit({ values, setError }))}
    >
      <Card className={classes.card}>
        <Icon fontSize="large" className={classes.headerIcon}>
          phonelink_lock
        </Icon>
        <Stepper activeStep={activeStep} orientation="vertical">
          <Step key="phone_number">
            <StepLabel>Scan the QR</StepLabel>
            <StepContent>
              <img alt="qr code" className={classes.qrCodeImage} src={qrCodeImage} />

              {isMobile && (
                <Typography variant="body2" paragraph>
                  <Button
                    variant="outlined"
                    component="a"
                    href={otpAuthURL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Open in Authenticator
                  </Button>
                </Typography>
              )}
              {!isRevealed && (
                <Button
                  className={classes.revealButton}
                  onClick={() => setReveal(true)}
                  variant="outlined"
                >
                  Reveal Setup Key
                </Button>
              )}
              {isRevealed && (
                <Typography variant="body1" paragraph>
                  {`Setup Key: ${base32}`}
                </Typography>
              )}

              <Typography variant="body2" paragraph>
                A common application for TOTP is Google Authenticator
              </Typography>
              <div>
                <a
                  href="https://itunes.apple.com/ca/app/google-authenticator/id388497605?mt=8"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img alt="Apple App Store" className={classes.appStoreBadge} src={appStoreBadge} />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img alt="Google Play Store" className={classes.appStoreBadge} src={playStoreBadge} />
                </a>
              </div>

              <Button
                color="primary"
                type="submit"
                variant="contained"
                size="small"
                className={classes.nextButton}
                disabled={formState.isSubmitting}
              >
                Next
              </Button>
            </StepContent>
          </Step>
          <Step key="sms_code">
            <StepLabel>Enter TOTP code</StepLabel>
            <StepContent>
              <TextField
                className={classes.codeInput}
                name="code"
                label="6 Digit code*"
                placeholder="123456"
                variant="outlined"
                autoComplete="off"
                autoFocus
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                inputRef={(e) => {
                  codeRef.current = e;
                  register(e, {
                    required: 'Required',
                    maxLength: {
                      value: 8,
                      message: 'Must be less then 9 digits',
                    },
                    pattern: {
                      value: /^\d+$/,
                      message: 'Invalid 2FA Code',
                    },
                  });
                }}
                error={errors.code != null}
                helperText={errors.code?.message}
              />

              <div className={classes.actions}>
                <Button
                  variant="outlined"
                  size="small"
                  disabled={formState.isSubmitting}
                  onClick={onBackClick}
                  className={classes.backButton}
                >
                  Back
                </Button>

                <Button
                  color="primary"
                  size="small"
                  type="submit"
                  variant="contained"
                  disabled={formState.isSubmitting}
                >
                  Next
                </Button>
              </div>
            </StepContent>
          </Step>
          <Step key="verify_sms_code">
            <StepLabel>Verify access code</StepLabel>
            <StepContent className={classes.verifyStepContent}>
              <div className={classes.verifyInner}>
                <CircularProgress
                  size={100}
                />
              </div>
            </StepContent>
          </Step>
        </Stepper>
      </Card>
      <Button variant="contained" component={Link} to="./two-factor-setup">
        Start Over
      </Button>
    </form>
  );
};

export default TOTPSetupView;
