import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  card: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    marginBottom: theme.spacing(2),
    width: 430,
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(2),
  },
}));
