import { makeStyles } from '@mui/styles';

// @ts-ignore
export default makeStyles((theme) => ({
  termsText: {
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(5),
    height: '30vh',
    overflowY: 'scroll',
  },
  acceptTermsButton: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(5),
  },
}));
