"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.base64ToBuffer = exports.publicKeyCredentialToJSON = void 0;
var bufferToBase64 = function (buf) {
    var bufferArray = new Uint8Array(buf);
    var binstr = Array.prototype.map.call(bufferArray, function (c) { return String.fromCharCode(c); }).join('');
    return btoa(binstr);
};
// Gets all enumerable [k, v] pairs in the Object and it's prototype chain
var prototypeEntries = function (data) {
    var entries = [];
    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (var k in data) {
        // eslint-disable-next-line security/detect-object-injection
        entries.push([k, data[k]]);
    }
    return entries.filter(function (_a) {
        var v = _a[1];
        return typeof v !== 'function';
    });
};
/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
var publicKeyCredentialToJSON = function (data) {
    if (data instanceof Array) {
        return data.map(exports.publicKeyCredentialToJSON);
    }
    if (data instanceof ArrayBuffer) {
        return bufferToBase64(data);
    }
    if (data instanceof Object) {
        var entries = prototypeEntries(data).map(function (_a) {
            var k = _a[0], v = _a[1];
            return [k, (0, exports.publicKeyCredentialToJSON)(v)];
        });
        return Object.fromEntries(entries);
    }
    return data;
};
exports.publicKeyCredentialToJSON = publicKeyCredentialToJSON;
var base64ToBuffer = function (base64) {
    var binstr = atob(base64);
    var charCodes = Array.prototype.map.call(binstr, function (c) { return c.charCodeAt(0); });
    return Uint8Array.from(charCodes);
};
exports.base64ToBuffer = base64ToBuffer;
