import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  friction: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  userFrictionMessage: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(5),
    textAlign: 'center',
  },
  frictionProgress: {
    '& circle': {
      transition: 'stroke-dashoffset 300ms linear 0ms',
    },
  },
}));
