// @ts-ignore
import lastwallLogo from '../../assets/img/lw-logo.svg';
import stageQueryFragment from './stage/stageQuery.fragment';
import { updateStage } from './stage/stageStore';

export const orgProfileQuery = `
  query($input: OrgProfileInput!) {
    orgProfile(input: $input) {
      authSessionSlug
      policyID
      logoURL
      backgroundURL
      serviceSignInURL
      pivDomain
      csrfToken

      enableTermsAndConditions
      termsAndConditionsMarkdown
      termsAndConditionsAcceptButtonText

      friction {
        type
        message
        timeout
      }

      ${stageQueryFragment}
    }
  }
`;

const defaultOrgProfile = {
  loaded: false,
  authSessionSlug: null,
  policyID: null,
  logoURL: lastwallLogo,
  backgroundURL: null,
  serviceSignInURL: null,
  pivDomain: null,
  csrfToken: null,
  friction: {
    enabled: false,
    type: false,
    timeout: 15,
    message: 'Please wait',
  },
};

export const updateOrgProfile = (state, data) => {
  const {
    stage,
    ...orgProfile
  } = data.orgProfile;

  orgProfile.loaded = true;
  orgProfile.logoURL = orgProfile.logoURL || lastwallLogo;

  const nextState = updateStage(state, { stage });

  return {
    ...nextState,
    orgProfile,
  };
};

export default defaultOrgProfile;
