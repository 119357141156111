import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    marginBottom: theme.spacing(5),
  },
  actions: {
    marginTop: theme.spacing(2),
    textAlign: 'right',
  },
  progress: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
}));
