export interface Stage {
  state: string
  forceOnboarding: boolean
  numRequired: number
  nextPolicyRequirements: [FactorVerification]
  factors: [Factor]
  factorAuthorizationTokens?: [FactorAuthorizationToken]
}

export interface FactorVerification {
  type: string
  verified: boolean
}

export interface Factor {
  id: string
  type: string
  passed: boolean
  failed: number
  extras?: any
}

// A token that can be send to the server to authorize a pre-determined factor on the given user
export interface FactorAuthorizationToken {
  id: string
  username: string
}

// export interface Authorization {
//   samlACS: string,
//   samlResponse: any,
//   relayState?: boolean,
// }

export const setRiscStorageCookie = (riscStorageCookie) => {
  let localStorage;

  // Chrome incognito throws an error upon trying to get window.localStorage
  try {
    localStorage = window.localStorage;
  // eslint-disable-next-line no-empty
  } catch {
  }

  localStorage?.setItem('lw_risc', riscStorageCookie);
};

export const updateStage = (state, payload) => {
  const { stage, resetRequired, riscStorageCookie } = payload;

  // check to see if the response has a storage_data and store cookie
  if (riscStorageCookie) {
    setRiscStorageCookie(riscStorageCookie);
  }

  return {
    ...state,
    stage,
    resetRequired: resetRequired == null ? state.resetRequired : resetRequired,
  };
};

const initialStage = null;

export default initialStage;
