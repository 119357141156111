import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  title: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
    // textAlign: 'center',
  },
  list: {
    // paddingLeft: theme.spacing(5),
    // paddingRight: theme.spacing(5),
    // paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(4),
  },
}));
