import React from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import useStyles from './Success.styles';
import CardWithLogo from '../../shared/card/CardWithLogo';

const SuccessView = ({
  logoURL,
  message,
  error,
}) => {
  const classes = useStyles();

  return (
    <CardWithLogo logoURL={logoURL}>
      <div className={classes.success}>
        {error == null && (
          <CircularProgress
            size={100}
          />
        )}
        <Typography
          variant={error ? 'h6' : 'body1'}
          paragraph
          color={error ? 'error' : 'textPrimary'}
          className={classes.message}
        >
          {error || message}
        </Typography>
      </div>
    </CardWithLogo>
  );
};

export default SuccessView;
