import React from 'react';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import CardWithLogo from './CardWithLogo';

const CardWithStepper = ({
  logoURL,
  activeStep,
  children,
  showStepper = true,
}) => (
  <CardWithLogo logoURL={logoURL}>
    { showStepper && (
      <Stepper activeStep={activeStep}>
        <Step>
          <StepLabel />
        </Step>
        <Step>
          <StepLabel />
        </Step>
      </Stepper>
    )}
    {children}
  </CardWithLogo>
);

export default CardWithStepper;
