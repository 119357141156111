import React, { useContext, useEffect } from 'react';
import { useStateMachine } from 'little-state-machine';
import useAsync from '../../shared/useAsync';

import TwoFactorPIVView from './TwoFactorPIV.view';
import useNavigateToNext from '../../shared/hooks/useNavigateToNext';
import findFactor from '../../store/stage/findFactor';
import { useFetchGraphQL } from '../../shared/apiFetchOptions';
import stageQueryFragment from '../../store/stage/stageQuery.fragment';
import RISCContext from '../../shared/risc/RISCContext';

const ONE_BILLION = 1000 * 1000 * 1000;

const TwoFactorPivPage = () => {
  const navigateToNext = useNavigateToNext();
  const risc = useContext(RISCContext);
  const { state } = useStateMachine() as any;
  const { factors } = state.stage;
  const factor = findFactor(state, 'PIV');

  const consumeFATokenMutation = `
    mutation($input: ConsumeFactorAuthorizationTokenInput!) {
      consumeFactorAuthorizationToken(input: $input) {
        ${stageQueryFragment}
      }
    }
  `;

  const consumeFAToken: any = useFetchGraphQL(async ({ fetchGraphQL, args }) => {
    const riscData = await risc.getRiscData(true);

    return fetchGraphQL({
      state,
      query: consumeFATokenMutation,
      variables: {
        input: {
          tokenID: args[0],
          riscData: JSON.stringify(riscData),
        },
      },
    });
  }, [state]);

  const fetchPIV = useAsync({
    deferFn: async (_args, { signal }) => {
      const { pivDomain, csrfToken } = state.orgProfile;

      const subdomain = Math.floor(Math.random() * ONE_BILLION);

      let domainAndProtocol = `https://${subdomain}.${pivDomain}`;
      if (process.env.NODE_ENV === 'development') {
        domainAndProtocol = `http://${pivDomain}`;
      }

      const url = `${domainAndProtocol}/api/v2/piv-check?`
        + `csrfToken=${encodeURIComponent(csrfToken)}`
        + `&factorID=${encodeURIComponent(factor.id)}`;

      const res = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        // headers: {
        //   authorization: `Bearer ${csrfToken}`,
        //   // 'Content-Type': 'application/json',
        // },
        signal,
        // body: JSON.stringify({
        //   factorID: factor.id,
        // }),
      });

      let json;
      try {
        json = await res.json();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        throw new Error('An error occurred. Please try again');
      }

      if (!res.ok || json.errors?.length) {
        throw new Error(json.errors[0].message);
      }

      const pivResp = json.data.createPIVAuthorizationTokens;
      const tokens = pivResp.stage.factorAuthorizationTokens;

      if (tokens.length === 1) {
        const consumeRes = await consumeFAToken.run(tokens[0].id);
        // console.log({ consumeRes })

        navigateToNext(consumeRes.consumeFactorAuthorizationToken);
      } else {
        navigateToNext(pivResp);
      }
    },
  });

  useEffect(() => {
    if (fetchPIV.error != null) {
      // eslint-disable-next-line no-console
      console.error(fetchPIV.error);
    }
  }, [fetchPIV.error]);

  return (
    <TwoFactorPIVView {...{
      logoURL: state.orgProfile.logoURL,
      isPending: fetchPIV.isPending,
      error: (fetchPIV.error || consumeFAToken.error)?.message,
      factors,
      onSubmit: fetchPIV.run,
    }}
    />
  );
};

export default TwoFactorPivPage;
