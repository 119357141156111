import React, { useContext, useEffect } from 'react';

import { useStateMachine } from 'little-state-machine';
import * as Webauthn from '@lastwall/webauthn-client';

import useNavigateToNext from '../../shared/hooks/useNavigateToNext';
import { useFetchGraphQL } from '../../shared/apiFetchOptions';
import stageQueryFragment from '../../store/stage/stageQuery.fragment';

import TwoFactorWebAuthnView from './TwoFactorWebAuthn.view';
import RISCContext from '../../shared/risc/RISCContext';

const TwoFactorWebAuthnPage = () => {
  const navigateToNext = useNavigateToNext();
  const risc = useContext(RISCContext);

  const { state } = useStateMachine() as any;
  const { factors } = state.stage;

  const createChallengeMutation = `
    mutation {
      createWebauthnLoginChallenge {
        id
        requestOptions
      }
    }
  `;

  const tryLoginMutation = `
    mutation($input: TryWebauthnInput!) {
      tryWebauthnLogin(input: $input) {
        ${stageQueryFragment}
      }
    }
  `;

  const authorize: any = useFetchGraphQL(async ({ fetchGraphQL }) => {
    const challengeRes = await fetchGraphQL({
      state,
      query: createChallengeMutation,
    });

    const { requestOptions, id: challengeID } = challengeRes.createWebauthnLoginChallenge;

    let assertion;
    try {
      assertion = await Webauthn.get(requestOptions);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      throw new Error(
        'Authentication Failed. '
        + 'Please contact your systems administrator to register this device for WebAuthn.',
      );
    }

    const riscData = await risc.getRiscData(true);

    const tryLoginRes = await fetchGraphQL({
      state,
      query: tryLoginMutation,
      variables: {
        input: {
          challengeID,
          assertion,
          riscData: JSON.stringify(riscData),
        },
      },
    });

    // stop risc from collecting data to avoid console.log errors
    risc.stopRisc(true);

    navigateToNext(tryLoginRes.tryWebauthnLogin);
  }, [state]);

  useEffect(() => {
    authorize.run();
  }, []);

  return (
    <TwoFactorWebAuthnView {...{
      logoURL: state.orgProfile.logoURL,
      error: authorize.error?.message,
      pending: authorize.isPending,
      factors,
      onSubmit: authorize.run,
    }}
    />
  );
};

export default TwoFactorWebAuthnPage;
