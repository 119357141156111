import React, {
  createRef, useState, useEffect, useLayoutEffect,
} from 'react';

import { useForm } from 'react-hook-form';
import FadeIn from 'react-fade-in';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import useStyles from './Password.styles';
import { useRISCInputRef } from '../../shared/risc/RISCContext';

const Step2Password = ({
  onBack,
  onSubmit,
  isSubmitting = false,
  defaultValues,
  error: externalError = null,
}) => {
  const classes: any = useStyles();

  const [hidePasssword, setHidePasssword] = useState(true);

  const ua = navigator.userAgent;
  const isPanGlobalProtect = ua.toLowerCase().includes('globalprotect');

  const passwordRef: any = createRef();
  useRISCInputRef(passwordRef, true);
  useLayoutEffect(() => passwordRef.current.focus(), []);

  const {
    handleSubmit,
    register,
    errors,
    setError,
    formState,
    reset,
  } = useForm({ defaultValues });

  const error = errors.password?.message;

  useEffect(() => {
    if (!isSubmitting && externalError) {
      reset();
      setError('password', {
        type: 'server',
        message: externalError,
        shouldFocus: true,
      });
    }
  }, [externalError, isSubmitting]);

  return (
    <form
      className={classes.formContainer}
      // @ts-ignore
      onSubmit={handleSubmit((values) => onSubmit({ values, setError }))}
    >
      <FadeIn delay={100} transitionDuration={500}>
        <Typography variant="h2" className={classes.title}>
          {defaultValues.username}
        </Typography>
        <TextField
          name="password"
          label="Enter your password"
          variant="outlined"
          type={hidePasssword ? 'password' : 'text'}
          autoComplete="off"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            autoFocus: true,
            endAdornment: isPanGlobalProtect ? undefined : (
              <InputAdornment position="end">
                <i
                  className={`material-icons ${classes.hidePassword}`}
                  onClick={() => setHidePasssword(!hidePasssword)}
                  onKeyPress={(e) => e.keyCode === 13 && setHidePasssword(!hidePasssword)}
                  role="button"
                  tabIndex={0}
                >
                  {hidePasssword ? 'visibility' : 'visibility_off'}
                </i>
              </InputAdornment>
            ),
          }}
          inputRef={(e) => {
            passwordRef.current = e;
            register(e, {
              required: 'Required',
            });
          }}
          error={error != null}
          helperText={error}
        />
        <div className={classes.formButtons}>
          <Button onClick={onBack}>Back</Button>
          <Button
            color="primary"
            type="submit"
            variant="contained"
            disabled={isSubmitting || formState.isSubmitting}
          >
            SUBMIT
          </Button>
        </div>
      </FadeIn>
    </form>
  );
};

export default Step2Password;
