import React, { useEffect } from 'react';

import { useStateMachine } from 'little-state-machine';

import RecoveryCodeSetupView from './RecoveryCodeSetup.view';
import { useFetchGraphQL } from '../../shared/apiFetchOptions';

const RecoveryCodeSetupPage = () => {
  const { state } = useStateMachine() as any;

  const createRecoveryCodeBatchMutation = `
    mutation($input: CreateRecoveryCodeBatchInput!) {
      createRecoveryCodeBatch(input: $input) {
        id
        codes {
          id
          serial
          value
        }
      }
    }
  `;

  const createRecoveryCodeBatch: any = useFetchGraphQL(({ fetchGraphQL }) => (
    fetchGraphQL({
      state,
      query: createRecoveryCodeBatchMutation,
      variables: {
        input: {
          // TODO: We should indicate to the user that recovery codes will expire
          ttlDays: 30 * 6, // 6 Months
          replaceExisting: true,
          numberOfCodes: 10,
        },
      },
    })
  ), [state]);

  useEffect(createRecoveryCodeBatch.run, []);

  if (createRecoveryCodeBatch.isPending) {
    // https://github.com/async-library/react-async/issues/242
    return <div />;
  }

  return (
    <RecoveryCodeSetupView {...{
      // @ts-ignore
      error: createRecoveryCodeBatch.error?.message,
      recoveryCodes: createRecoveryCodeBatch.data?.createRecoveryCodeBatch.codes,
    }}
    />
  );
};

export default RecoveryCodeSetupPage;
