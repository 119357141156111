// username gets stored in localstorage on successful login
let user = '';
try {
  user = localStorage.getItem('username');
} catch (e) {
  // console.log('localStorage is not enabled or is full');
}

const login = {
  username: user,
  password: '',
};

export const updateLogin = (state, payload) => ({
  ...state,
  login: {
    ...state.login,
    ...payload,
  },
});

export default login;
