import { useCallback, useEffect, useState } from 'react';

const cancelledMessage = 'useAsync promise cancelled';

const useAsync = ({ deferFn }) => {
  const [state, setState] = useState({
    data: null,
    error: null,
    promise: null,
    settled: false,
    cancelFn: () => {},
  });

  const run = useCallback((...args) => {
    const controller = new AbortController();

    let isSettled = false;
    let cancelFn;
    const cancelPromise = new Promise((_, reject) => {
      cancelFn = () => {
        if (!isSettled) {
          const error = new Error(cancelledMessage);

          // handle defer functions that use the abort controller
          controller.abort();
          // handle defer functions that don't use the abort controller
          reject(error);

          // Update the state
          setState({
            data: null,
            error,
            promise: null,
            settled: true,
            cancelFn: () => {},
          });
        }
      };
    });

    const promise = Promise.race([
      deferFn(args, { signal: controller.signal })
        .then((data) => {
          setState({
            data,
            error: null,
            promise: null,
            settled: true,
            cancelFn: () => {},
          });
          return data;
        })
        .catch((error) => {
          setState({
            data: null,
            error,
            promise: null,
            settled: true,
            cancelFn: () => {},
          });
          throw error;
        }),
      cancelPromise,
    ])
      .then((r) => {
        isSettled = true;
        return r;
      })
      .catch((e) => {
        isSettled = true;
        throw e;
      });

    setState((previousState) => ({
      ...previousState,
      promise,
      cancelFn,
    }));

    return promise;
  }, [deferFn]);

  // When a component un-mounts or when run is called for a second time the previous promise gets
  // cancelled.
  useEffect(() => () => setState((nextState) => {
    if (!nextState.settled) {
      state.cancelFn();
    }
    return nextState;
  }), [state.cancelFn]);

  return {
    run,
    cancel: state.cancelFn,
    data: state.data,
    error: state.error,
    isInitial: state.promise == null && !state.settled,
    isPending: state.promise != null && !state.settled,
    isSettled: state.settled,
  };
};

export default useAsync;
