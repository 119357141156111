import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    height: 300,
    width: 400,
  },
  progress: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  doneIcon: {
    '& i': {
      marginBottom: theme.spacing(2),
      fontSize: 50,
      color: '#388e3c',
    },
  },
  message: {
  },
}));
