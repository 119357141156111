import React from 'react';
import { useStateMachine } from 'little-state-machine';

import TwoFactorTOTPView from './TwoFactorTOTP.view';
import useNavigateToNext from '../../shared/hooks/useNavigateToNext';
import { useFetchGraphQL } from '../../shared/apiFetchOptions';
import stageQueryFragment from '../../store/stage/stageQuery.fragment';

const TwoFactorTOTPPage = () => {
  const navigateToNext = useNavigateToNext();

  const { state } = useStateMachine() as any;
  const { factors } = state.stage;

  const tryTOTPCodeMutation = `
    mutation($input: TryTOTPCodeInput!) {
      tryTOTPCode(input: $input) {
        ${stageQueryFragment}
      }
    }
  `;

  const tryTOTPCode: any = useFetchGraphQL(async ({ args, fetchGraphQL }) => {
    const { code } = args[0].values;

    const data = await fetchGraphQL({
      state,
      query: tryTOTPCodeMutation,
      variables: {
        input: {
          code,
        },
      },
    });

    navigateToNext(data.tryTOTPCode);
  }, [state]);

  return (
    <TwoFactorTOTPView {...{
      error: tryTOTPCode.error?.message,
      factors,
      // failed: factor.failed,
      onSubmit: tryTOTPCode.run,
      isSubmitting: tryTOTPCode.isPending,
    }}
    />
  );
};

export default TwoFactorTOTPPage;
