import React from 'react';
import { Link } from 'react-router-dom';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

import useStyles from './TwoFactorSetup.styles';
import factorsDisplayText from '../../shared/factorsDisplayText';
import CardWithLogo from '../../shared/card/CardWithLogo';

export const factorMetaData = {
  SMS: {
    icon: 'sms',
    // SetupComponent: TwoFactorSMSSetup,
  },
  TOTP: {
    icon: 'phonelink_lock',
    // SetupComponent: TwoFactorTOTPSetup,
  },
  PIV: {
    icon: 'vpn_key',
    // SetupComponent: TwoFactorPIVSetup,
  },
  RECOVERY_CODE: {
    icon: 'assignment',
    // SetupComponent: TwoFactorRecoverySetup,
  },
  WEBAUTHN: {
    icon: 'vpn_key',
    // SetupComponent: TwoFactorWebauthnSetup
    // disabled: true,
  },
};

const TwoFactorSetupView = ({
  nextPolicyRequirements,
  credentialSummaries,
  forceOnboarding,
  authorizedToLogin,
  error = null,
  login = () => {},
  logoURL,
  deleteCredential,
}) => {
  const classes = useStyles();
  const ua = navigator.userAgent;
  const isPanGlobalProtect = ua.toLowerCase().includes('globalprotect');

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.only('xs'));

  const [deleteDialogData, setDeleteDialogData] = React.useState<any>({
    credential: null,
    open: false,
  });
  const handleDeleteDialogClose = () => setDeleteDialogData((data) => ({
    ...data,
    open: false,
  }));

  const [dropDownAnchorEl, setDropDownAnchorEl] = React.useState<null | HTMLElement>(null);
  const dropDownOpen = Boolean(dropDownAnchorEl);
  const handleAddClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setDropDownAnchorEl(event.currentTarget);
  };
  const handleDropDownClose = () => {
    setDropDownAnchorEl(null);
  };

  if (error) {
    return (
      <Typography color="error">
        {error}
      </Typography>
    );
  }

  return (
    <div>
      <CardWithLogo className={classes.card} logoURL={logoURL}>
        <Typography variant="h6" paragraph>
          Credential Management
        </Typography>
        { forceOnboarding && (
          <>
            <Typography variant="body2" paragraph>
              Your account has been set into Credential Management in order for you to add or
              modify your credentials.
            </Typography>
            <Typography variant="body2" paragraph>
              Please add any missing
              credentials as specified by your systems administrator.
            </Typography>
          </>
        )}
        { !authorizedToLogin && !forceOnboarding && (
          <>
            <Typography variant="body2" paragraph>
              Your organization&apos;s security policy has recently changed.
            </Typography>
            <Typography variant="body2" paragraph>
              Please add any missing
              credentials as specified by your systems administrator to access your account.
            </Typography>
          </>
        )}
        { authorizedToLogin && !forceOnboarding && (
          <Typography variant="body2" paragraph>
            You are in compilance with your oraganization&apos;s security policy
            and authorized to login
          </Typography>
        )}
        <List
          aria-labelledby="two-factor-setup-subheader"
          // @ts-ignore
          subheader={(
            <ListSubheader
              component="div"
              id="two-factor-setup-subheader"
              disableGutters
            >
              Authorized Credentials
            </ListSubheader>
          )}
        >
          { credentialSummaries.length === 0 && (
            <ListItem
              disableGutters
            >

              <ListItemText
                primary="This is a fresh account, please add your security credentials bellow."
              />
            </ListItem>
          )}
          { credentialSummaries.map((credential) => {
            const { id, type, redactedDescriptor } = credential;
            const { icon, disabled } = factorMetaData[`${type}`] || {};

            if (disabled) {
              return null;
            }

            return (
              <ListItem
                disableGutters
                key={`${type}-${id}`}
              >
                { !isPanGlobalProtect && (
                  <ListItemIcon>
                    <i className="material-icons">
                      { icon }
                    </i>
                  </ListItemIcon>
                )}
                <ListItemText
                  primary={redactedDescriptor}
                />
                { !isPanGlobalProtect && (
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => setDeleteDialogData({ open: true, credential })}
                      size="large">
                      <i className="material-icons">
                        delete
                      </i>
                    </IconButton>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            );
          })}
        </List>
        <div className={classes.addButtonWrapper}>
          <Button
            id="add-button"
            color="primary"
            aria-label="add"
            // className={classes.addButton}
            variant="outlined"
            onClick={handleAddClick}
          >
            <i className="material-icons">
              add
            </i>
            Credential
          </Button>
        </div>
        <Menu
          anchorEl={dropDownAnchorEl}
          getContentAnchorEl={null}
          open={dropDownOpen}
          onClose={handleDropDownClose}
          MenuListProps={{
            'aria-labelledby': 'add-button',
          }}
          anchorOrigin={{
            vertical: mobile ? 'top' : 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: mobile ? 'bottom' : 'top',
            horizontal: 'center',
          }}
        >
          {Object.entries(factorsDisplayText).map(([type, { setupDescription }]) => {
            const credPolicyRequirement = nextPolicyRequirements.find((r) => r.type === type);

            if (credPolicyRequirement == null) {
              return null;
            }

            return (
              <MenuItem
                key={type}
                button
                component={Link}
                to={`./setup-${type.replace('_', '-').toLowerCase()}`}
              >
                {setupDescription}
              </MenuItem>
            );
          })}
        </Menu>
        <Dialog
          open={deleteDialogData.open}
          onClose={handleDeleteDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {`Delete ${deleteDialogData.credential?.redactedDescriptor}?`}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete
              {`${deleteDialogData.credential?.redactedDescriptor}?`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteDialogClose}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                deleteCredential(deleteDialogData);
                handleDeleteDialogClose();
              }}
              color="primary"
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </CardWithLogo>
      <div className={classes.actions}>
        <Button
          variant="contained"
          color="primary"
          disabled={!authorizedToLogin}
          onClick={login}
        >
          Finish
        </Button>
      </div>
    </div>
  );
};

export default TwoFactorSetupView;
