import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    marginBottom: theme.spacing(5),
  },
  message: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));
