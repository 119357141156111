import React from 'react';
import { useStateMachine } from 'little-state-machine';
import { useHistory, useParams } from 'react-router-dom';

import LoginView from './Login.view';
import { useFetchGraphQL } from '../../shared/apiFetchOptions';
import { updateLogin } from '../../store/loginStore';
import { updateStage } from '../../store/stage/stageStore';
import stageQueryFragment from '../../store/stage/stageQuery.fragment';

const LoginPage = () => {
  const history = useHistory();
  const { actions, state } = useStateMachine({
    updateLogin,
    updateStage,
  }) as any;
  const { factor: factorType } = useParams();

  const { factors } = state.stage;

  const tryEmailMutation = `
    mutation($input: TryEmailInput!) {
      tryEmail(input: $input) {
        ${stageQueryFragment}
      }
    }
  `;

  const tryEmail: any = useFetchGraphQL(async ({ args, fetchGraphQL }) => {
    const { values } = args[0];

    actions.updateLogin(values);

    const data = await fetchGraphQL({
      state,
      query: tryEmailMutation,
      variables: {
        input: {
          email: values.username,
        },
      },
    });

    actions.updateStage(data.tryEmail);
    history.push('./');
  }, [state]);

  return (
    <LoginView {...{
      factors,
      factorType,
      error: tryEmail.error?.message,
      onSubmit: tryEmail.run,
      isSubmitting: tryEmail.isPending,
    }}
    />
  );
};

export default LoginPage;
