import React, { useEffect } from 'react';

import { useStateMachine } from 'little-state-machine';

import TwoFactorSMSView from './TwoFactorSMS.view';
import useNavigateToNext from '../../shared/hooks/useNavigateToNext';
import findFactor from '../../store/stage/findFactor';
import { useFetchGraphQL } from '../../shared/apiFetchOptions';
import stageQueryFragment from '../../store/stage/stageQuery.fragment';

const TwoFactorSMSPage = () => {
  const navigateToNext = useNavigateToNext();

  const { state } = useStateMachine() as any;
  const { factors } = state.stage;
  const factor = findFactor(state, 'SMS');

  const sendSMSCodeMutation = `
    mutation {
      sendSMSCode {
        id
      }
    }
  `;

  const sendSMSToPhone: any = useFetchGraphQL(async ({ fetchGraphQL }) => {
    await fetchGraphQL({
      state,
      query: sendSMSCodeMutation,
    });

    // Throttle SMS messages to one every 5 seconds
    await new Promise((resolve) => setTimeout(resolve, 5000));
  }, [state]);

  useEffect(sendSMSToPhone.run, []);

  const trySMSCodeMutation = `
    mutation($input: TrySMSCodeInput!) {
      trySMSCode(input: $input) {
        ${stageQueryFragment}
      }
    }
  `;

  const trySMSCode: any = useFetchGraphQL(async ({ args, fetchGraphQL }) => {
    const { code } = args[0].values;

    const data = await fetchGraphQL({
      state,
      query: trySMSCodeMutation,
      variables: {
        input: {
          code,
        },
      },
    });

    navigateToNext(data.trySMSCode);
  }, [state]);

  const error = sendSMSToPhone.error || trySMSCode.error;

  return (
    <TwoFactorSMSView {...{
      factors,
      failed: factor.failed,
      onSubmit: trySMSCode.run,
      isSubmitting: trySMSCode.isPending,
      onResendClick: sendSMSToPhone.run,
      isResending: sendSMSToPhone.isPending,
      error: error?.message,
    }}
    />
  );
};

export default TwoFactorSMSPage;
