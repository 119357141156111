import React, { useRef, useLayoutEffect, useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
// import MaterialUIPhoneNumber from 'material-ui-phone-number'

import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Icon from '@mui/material/Icon';
import TextField from '@mui/material/TextField';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';

import useStyles from './SMSSetup.styles';

export interface SMSSetupProps {
  onSubmit?: (values: any) => Promise<void>
  onBackClick?: () => void
  createPhoneChannelError?: string
  verifyPhoneChannelError?: string
  isSubmittingCreate?: boolean
  isSubmittingVerify?: boolean
  activeStep?: number
}

const SMSSetupView = ({
  onSubmit = async () => {},
  onBackClick = () => {},
  createPhoneChannelError,
  verifyPhoneChannelError,
  isSubmittingCreate = false,
  isSubmittingVerify = false,
  activeStep = 0,
}: SMSSetupProps) => {
  const classes = useStyles();

  const {
    handleSubmit,
    register,
    errors,
    // setValue,
    setError,
    reset,
    clearErrors,
  } = useForm();

  useEffect(() => {
    if (!isSubmittingCreate && createPhoneChannelError) {
      setError('phoneNumber', {
        type: 'server',
        message: createPhoneChannelError,
        shouldFocus: true,
      });
    }
  }, [createPhoneChannelError, isSubmittingCreate]);

  useEffect(() => {
    if (!isSubmittingVerify && verifyPhoneChannelError) {
      setError('code', {
        type: 'server',
        message: verifyPhoneChannelError,
        shouldFocus: true,
      });
    }
  }, [verifyPhoneChannelError, isSubmittingVerify]);

  const phoneRef: any = useRef();
  const codeRef: any = useRef();

  useLayoutEffect(() => {
    if (activeStep === 0) {
      phoneRef.current.focus();
    }
    if (activeStep === 1) {
      codeRef.current.focus();
    }
  }, [activeStep]);

  return (
    <form
      // @ts-ignore
      onSubmit={handleSubmit((values) => onSubmit({ values }))}
    >
      <Card className={classes.card}>
        <Icon fontSize="large" className={classes.headerIcon}>
          phonelink_lock
        </Icon>
        <Stepper activeStep={activeStep} orientation="vertical">
          <Step key="phone_number">
            <StepLabel>Enter SMS phone number</StepLabel>
            <StepContent>
              <TextField
                // <MaterialUIPhoneNumber
                name="phoneNumber"
                // defaultCountry="us"
                // preferredCountries={['us', 'ca', 'gb', 'au', 'nz']}
                variant="outlined"
                fullWidth
                margin="dense"
                placeholder="+1 (101) 555-1234"
                // onChange={(value) => setValue('phoneNumber', value)}
                inputRef={(e) => {
                  phoneRef.current = e;
                  register(e, {
                    required: 'Required',
                    pattern: {
                      // eslint-disable-next-line security/detect-unsafe-regex
                      value: /^(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
                      message: 'Invalid Phone Number',
                    },
                  });
                }}
                error={errors.phoneNumber != null}
                helperText={errors.phoneNumber?.message}
              />
              <Button
                color="primary"
                type="submit"
                variant="contained"
                size="small"
                className={classes.nextButton}
                disabled={isSubmittingCreate}
              >
                Next
              </Button>
            </StepContent>
          </Step>
          <Step key="sms_code">
            <StepLabel>Enter SMS code</StepLabel>
            <StepContent>
              <TextField
                className={classes.codeInput}
                name="code"
                label="6 Digit code*"
                placeholder="123456"
                variant="outlined"
                autoComplete="off"
                autoFocus
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                inputRef={(e) => {
                  codeRef.current = e;
                  register(e, {
                    required: 'Required',
                    maxLength: {
                      value: 8,
                      message: 'Must be less then 9 digits',
                    },
                    pattern: {
                      value: /^\d+$/,
                      message: 'Invalid 2FA Code',
                    },
                  });
                }}
                error={errors.code != null}
                helperText={errors.code?.message}
              />

              <div className={classes.actions}>
                <Button
                  variant="outlined"
                  size="small"
                  disabled={isSubmittingVerify}
                  onClick={() => {
                    clearErrors();
                    reset();
                    onBackClick();
                  }}
                  className={classes.backButton}
                >
                  Back
                </Button>

                <Button
                  color="primary"
                  size="small"
                  type="submit"
                  variant="contained"
                  disabled={isSubmittingVerify}
                >
                  Next
                </Button>
              </div>
            </StepContent>
          </Step>
          <Step key="verify_code">
            <StepLabel>Verify Access Code</StepLabel>
            <StepContent className={classes.verifyStepContent}>
              <div className={classes.verifyInner}>
                <CircularProgress
                  size={100}
                />
              </div>
            </StepContent>
          </Step>
        </Stepper>
      </Card>
      <Button variant="contained" component={Link} to="./two-factor-setup">
        Start Over
      </Button>
    </form>
  );
};

export default SMSSetupView;
