// Logged in sessions skip through to the success page
// eslint-disable-next-line import/prefer-default-export
export const getLoggedInOrgProfile = () => {
  const loggedInAsString = localStorage?.getItem('loggedInAs');
  if (loggedInAsString == null) {
    return null;
  }
  const loggedInOrgProfile = JSON.parse(loggedInAsString);

  const { exp } = JSON.parse(atob(loggedInOrgProfile.csrfToken.split('.')[1]));

  const sessionIsExpired = Date.now() >= exp * 1000;
  if (sessionIsExpired) {
    return null;
  }

  return loggedInOrgProfile;
};
