import React, { useMemo } from 'react';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Icon from '@mui/material/Icon';
import Box from '@mui/material/Box';

const BackupCodeBatchView = ({
  backupCodes: recoveryCodes = [],
  children,
}) => {
  const getFormattedCode = (code) => (
    code.match(/.{1,4}/g).join('-')
  );

  const copyText = useMemo(() => {
    let text = 'Serial  Recovery Code\n';

    recoveryCodes.forEach((code) => {
      text += `${code.serial.toString().padEnd(7)} ${getFormattedCode(code.value)}\n`;
    });

    return text;
  }, [recoveryCodes]);

  return (
    <>
      <Typography variant="body2" color="textSecondary" paragraph>
        Store recovery codes in a secure manner as they can be used to access your account
      </Typography>
      <Typography variant="body2" color="textSecondary" paragraph>
        Any and all previous recovery codes will have been invalidated and replaced by the new
        recovery codes.
      </Typography>
      <TableContainer
        component={Paper}
      >
        <Table aria-label="recovery codes table" size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="body2" color="textSecondary">
                  Serial
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2" color="textSecondary">
                  Recovery Code
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {recoveryCodes.map((row) => (
              <TableRow key={row.serial}>
                <TableCell component="th" scope="row">
                  {row.serial}
                </TableCell>
                <TableCell>
                  {getFormattedCode(row.value)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{ mt: 2 }}>
        <CopyToClipboard text={copyText}>
          <Button
            color="secondary"
            size="small"
            startIcon={<Icon>content_copy</Icon>}
            sx={{ mr: 2 }}
          >
            Copy
          </Button>
        </CopyToClipboard>

        {children}
      </Box>
    </>
  );
};

export default BackupCodeBatchView;
