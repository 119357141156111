import React, {
  useEffect,
  useContext,
} from 'react';

import { useStateMachine } from 'little-state-machine';

import { useFetchGraphQL } from '../../shared/apiFetchOptions';
import RISCContext from '../../shared/risc/RISCContext';
import SuccessView from './Success.view';
import { getLoggedInOrgProfile } from '../../store/loggedInOrgProfileStore';
import { setRiscStorageCookie } from '../../store/stage/stageStore';

const SuccessPage = () => {
  const { state } = useStateMachine() as any;
  const risc = useContext(RISCContext);

  // successful login
  const createSAMLAssrtionMutation = `
    mutation($input: CreateSAMLAssertionInput!) {
      createSAMLAssertion(input: $input) {
        postURL
        postData
        riscStorageCookie
      }
    }
  `;

  const submitSAMLAssertion: any = useFetchGraphQL(async ({ fetchGraphQL }) => {
    const loggedInOrgProfile = getLoggedInOrgProfile();
    const riscData = {}; // await risc.getRiscData(true);

    const data = await fetchGraphQL({
      state,
      query: createSAMLAssrtionMutation,
      variables: {
        input: {
          loggedInCSRFToken: loggedInOrgProfile?.csrfToken,
          riscData: JSON.stringify(riscData),
        },
      },
    });

    // Uncomment to enable persistent logins

    // // Only set the logged in org profile on first log in.
    // if (loggedInOrgProfile == null) {
    //   localStorage.setItem('loggedInAs', JSON.stringify(state.orgProfile))
    // }

    // Save username for auto-population on next login
    try {
      // username may be blank on PIV/CAC login
      if (state.login.username) {
        localStorage.setItem('username', state.login.username);
      }
    } catch (e) {
      // console.log('Could not save username, localStorage is not enabled or is full');
    }

    const { postURL, postData, riscStorageCookie } = data.createSAMLAssertion;

    // Update the RISC storage cookie so it doesn't fall out of sync with the backend
    setRiscStorageCookie(riscStorageCookie);

    const form = document.createElement('form');
    form.action = postURL;
    form.method = 'POST';

    Object.entries(postData).forEach(([key, value]) => {
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = key;
      input.value = value as string;
      form.appendChild(input);
    });

    document.body.appendChild(form);
    form.submit();

    // Never resolve - the page is redirecting via form submission back to the SAML provider
    // eslint-disable-next-line
    await new Promise((_resolve) => {})
  }, [state]);

  useEffect(submitSAMLAssertion.run, []);

  useEffect(() => {
    // Delete the persistent credentials on error to prevent users from being locked out of their
    // accounts until their CSRF expires.
    if (submitSAMLAssertion.error) {
      localStorage.removeItem('loggedInAs');
    }
  }, [submitSAMLAssertion.error]);

  return (
    <SuccessView {...{
      logoURL: state.orgProfile.logoURL,
      error: submitSAMLAssertion.error?.message,
      message: 'Logging In',
    }}
    />
  );
};

export default SuccessPage;
