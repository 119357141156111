import React, { useContext } from 'react';
import {
  useHistory,
} from 'react-router-dom';

import { useStateMachine } from 'little-state-machine';
import RISCContext from '../../shared/risc/RISCContext';
import { useFetchGraphQL } from '../../shared/apiFetchOptions';
import PasswordView from './Password.view';
import useNavigateToNext from '../../shared/hooks/useNavigateToNext';
import stageQueryFragment from '../../store/stage/stageQuery.fragment';
// import { Factor } from '../../store/stage/stageStore'
import findFactor from '../../store/stage/findFactor';

const PasswordPage = () => {
  const history = useHistory();
  const navigateToNext = useNavigateToNext();
  const risc = useContext(RISCContext);
  const { state } = useStateMachine() as any;

  const factor = findFactor(state, 'PASSWORD');

  /*
   * If the user has already sumbitted their password then go back to the login
   * page to reset the auth session. The expectation is that the user entered the
   * wrong account and wishes to try again.
   */
  if (factor == null) {
    const { serviceSignInURL } = state.orgProfile;
    if (serviceSignInURL != null && serviceSignInURL.length > 0) {
      // eslint-disable-next-line no-console
      console.warn('Invalid state. Returning to service sign in URL.');
      document.location = state.orgProfile.serviceSignInURL;
    } else {
      throw new Error('Your session has expired. Please close this tab and try again.');
    }
  }

  const tryPasswordMutation = `
    mutation($input: TryPasswordInput!) {
      tryPassword(input: $input) {
        ${stageQueryFragment}
        resetRequired
        enrollmentComplete
      }
    }
  `;

  const tryPassword: any = useFetchGraphQL(async ({ args, fetchGraphQL }) => {
    const { values } = args[0];
    const riscData = await risc.getRiscData(true);

    const data = await fetchGraphQL({
      state,
      query: tryPasswordMutation,
      variables: {
        input: {
          ...values,
          riscData: JSON.stringify(riscData),
        },
      },
    });

    // stop risc from collecting data to avoid console.log errors
    risc.stopRisc(true);

    navigateToNext(data.tryPassword);
  }, [state]);

  const onBack = () => {
    history.push('/password/username');
  };

  return (
    <PasswordView {...{
      error: tryPassword.error?.message,
      onBack,
      onSubmit: tryPassword.run,
      isSubmitting: tryPassword.isPending,
      defaultValues: state.login,
    }}
    />
  );
};

export default PasswordPage;
