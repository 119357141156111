import React, { useContext } from 'react';
import { useStateMachine } from 'little-state-machine';

import UserSelectionView from './UserSelecton.view';
import useNavigateToNext from '../../shared/hooks/useNavigateToNext';
import { useFetchGraphQL } from '../../shared/apiFetchOptions';
import stageQueryFragment from '../../store/stage/stageQuery.fragment';
import RISCContext from '../../shared/risc/RISCContext';

const UserSelectionPage = () => {
  const navigateToNext = useNavigateToNext();
  const risc = useContext(RISCContext);

  const { state } = useStateMachine() as any;
  const { factorAuthorizationTokens } = state.stage;

  const consumeFATokenMutation = `
    mutation($input: ConsumeFactorAuthorizationTokenInput!) {
      consumeFactorAuthorizationToken(input: $input) {
        ${stageQueryFragment}
      }
    }
  `;

  const consumeFAToken: any = useFetchGraphQL(async ({ args, fetchGraphQL }) => {
    const riscData = await risc.getRiscData(true);
    const tokenID = args[0];

    const data = await fetchGraphQL({
      state,
      query: consumeFATokenMutation,
      variables: {
        input: {
          tokenID,
          riscData: JSON.stringify(riscData),
        },
      },
    });

    navigateToNext(data.consumeFactorAuthorizationToken);
  }, [state]);

  const { error } = consumeFAToken;

  return (
    <UserSelectionView {...{
      factorAuthorizationTokens,
      consumeToken: consumeFAToken.run,
      isPending: consumeFAToken.isPending,
      error: error?.message,
      logoURL: state.orgProfile.logoURL,
    }}
    />
  );
};

export default UserSelectionPage;
