import React from 'react';

import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';

import useStyles from './CardWithLogo.styles';

// import AdvancedMenu from './AdvancedMenu'

const CardWithLogo = ({
  logoURL,
  children,
  className: externalClassName = null,
}) => {
  const classes = useStyles();

  const className = [classes.card, externalClassName].filter((s) => s != null).join(' ');

  return (
    <Card className={className}>
      {/* <AdvancedMenu /> */}
      <CardMedia
        className={classes.logo}
        image={logoURL}
      />
      {children}
    </Card>
  );
};

export default CardWithLogo;
