// Note: Keeping this seperate as it will have to be moved into an i18n file for
// internationalization
const factorsDisplayText = {
  PASSWORD: {
    title: 'Password',
    setupDescription: '',
  },
  WEBAUTHN: {
    title: 'WebAuthn',
    setupDescription: 'WebAuthn security key',
  },
  PIV: {
    title: 'PIV/CAC',
    setupDescription: 'PIV/CAC key or smart card',
  },
  TOTP: {
    title: 'TOTP',
    setupDescription: 'TOTP authenticator app on your phone',
  },
  SMS: {
    title: 'SMS',
    setupDescription: 'SMS text message',
  },
  RECOVERY_CODE: {
    title: 'Recovery Code',
    setupDescription: 'Generate a list of security codes',
  },
};

export default factorsDisplayText;
