import React, { useEffect, useRef, useLayoutEffect } from 'react';

import { useForm } from 'react-hook-form';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import TextField from '@mui/material/TextField';

import useStyles from './TwoFactorTOTP.styles';
import TwoFactorSelector from '../two-factor-selector/TwoFactorSelector';

const TwoFactorTOTPView = ({
  factors,
  // failed,
  onSubmit = () => {},
  isSubmitting = false,
  error: externalError = null,
}) => {
  const classes = useStyles();

  const {
    handleSubmit,
    register,
    errors,
    setError,
    formState,
    reset,
  } = useForm();

  useEffect(() => {
    if (!isSubmitting && externalError) {
      reset();
      setError('code', {
        type: 'server',
        message: externalError,
        shouldFocus: true,
      });
    }
  }, [externalError, isSubmitting]);

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.only('xs'));
  const textFieldMargin = mobile ? 'dense' : 'normal';
  const textFieldSize = mobile ? 'small' : 'medium';
  const buttonSize = textFieldSize;

  const codeRef: any = useRef();
  useLayoutEffect(() => codeRef.current.focus(), []);

  // if (failed) {
  //   return (
  //     <Typography variant="body1" paragraph>
  //       Failed 2FA too many times. Please restart and try again.
  //     </Typography>
  //   )
  // }

  return (
    <form
      // @ts-ignore
      onSubmit={handleSubmit((values) => onSubmit({ values, setError }))}
    >
      <Card className={classes.card}>
        <Typography
          variant="body1"
          paragraph={!mobile}
          gutterBottom={mobile}
        >
          <Icon fontSize="inherit">
            phonelink_lock
          </Icon>
          Enter your TOTP code below
        </Typography>

        <TextField
          name="code"
          label="Enter 2FA code"
          placeholder="123456"
          variant="outlined"
          autoComplete="off"
          autoFocus
          fullWidth
          margin={textFieldMargin}
          size={textFieldSize}
          InputLabelProps={{
            shrink: true,
          }}
          inputRef={(e) => {
            codeRef.current = e;
            register(e, {
              required: 'Required',
              maxLength: {
                value: 8,
                message: 'Must be less then 9 digits',
              },
              pattern: {
                value: /^\d+$/,
                message: 'Invalid 2FA Code',
              },
            });
          }}
          error={errors.code != null}
          helperText={errors.code?.message}
        />

        <Button
          className={classes.button}
          color="primary"
          size={buttonSize}
          type="submit"
          variant="contained"
          disabled={isSubmitting || formState.isSubmitting}
        >
          Authenticate
        </Button>
        <TwoFactorSelector factors={factors.filter((x) => x.type !== 'TOTP')} />
      </Card>
    </form>
  );
};

export default TwoFactorTOTPView;
