import React from 'react';

import Typography from '@mui/material/Typography';
// import Button from '@mui/material/Button'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
// import ListSubheader from '@mui/material/ListSubheader'

import useStyles from './UserSelection.styles';
import CardWithLogo from '../../shared/card/CardWithLogo';

const UserSelectionView = ({
  error = null,
  isPending = false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  consumeToken = (_tokenID) => {},
  factorAuthorizationTokens,
  logoURL,
}) => {
  const classes = useStyles();
  const ua = navigator.userAgent;
  const isPanGlobalProtect = ua.toLowerCase().includes('globalprotect');

  if (error) {
    return (
      <Typography color="error">
        {error}
      </Typography>
    );
  }

  return (
    <div>
      {/* <Card className={classes.card}> */}
      <CardWithLogo logoURL={logoURL}>
        <Typography variant="h2" className={classes.title}>
          Select Account
        </Typography>
        <List
          aria-labelledby="two-factor-setup-subheader"
          className={classes.list}
          // @ts-ignore
          // subheader={(
          //   <ListSubheader component="div" id="two-factor-setup-subheader">
          //     Select Account
          //   </ListSubheader>
          // )}
        >
          { factorAuthorizationTokens.map(({ id, username }) => (
            <ListItem
              button
              key={id}
              onClick={() => consumeToken(id)}
              disabled={isPending}
            >
              { !isPanGlobalProtect && (
                <ListItemIcon>
                  <i className="material-icons">
                    account_circle
                  </i>
                </ListItemIcon>
              )}
              <ListItemText
                primary={(
                  <>
                    { username }
                  </>
                )}
              />
            </ListItem>
          ))}
        </List>
      </CardWithLogo>
    </div>
  );
};

export default UserSelectionView;
