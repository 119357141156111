import { Factor } from './stageStore';

const findFactor = (state, factorName) => {
  const { factors } = state.stage;
  const factor = factors.find((f: Factor) => f.type === factorName);

  if (factor == null) {
    // eslint-disable-next-line no-console
    console.warn(`No ${factorName} factor found`);
    throw new Error(
      `Your account is not setup with ${factorName.toLowerCase()} credentials or is missing a `
      + 'related multifactor authentication. Please use other credentials or contact your '
      + 'IT systems administrator.',
    );
  }

  return factor;
};

export default findFactor;
