import React, { useEffect } from 'react';

import Typography from '@mui/material/Typography';

import {
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';

import { useStateMachine } from 'little-state-machine';

import TermsAndConditionsPage from '../login-flow/terms-and-conditions/TermsAndConditions.page';
import LoginPage from '../login-flow/login/Login.page';
import FrictionPage from '../login-flow/friction/Friction.page';
import PasswordPage from '../login-flow/password/Password.page';

import TwoFactorSetupPage from '../onboarding/two-factor-setup/TwoFactorSetup.page';

import SMSSetupPage from '../onboarding/sms-setup/SMSSetup.page';
import PIVSetupPage from '../onboarding/piv-setup/PIVSetup.page';
import TOTPSetupPage from '../onboarding/totp-setup/TOTPSetup.page';
import WebauthnSetupPage from '../onboarding/webauthn-setup/WebauthnSetup.page';
import RecoveryCodeSetupPage from '../onboarding/recovery-code-setup/RecoveryCodeSetup.page';

import TwoFactorRecoveryCodePage from '../login-flow/two-factor-recovery-code/TwoFactorRecoveryCode.page';
import TwoFactorPIVPage from '../login-flow/two-factor-piv/TwoFactorPIV.page';
import TwoFactorSMSPage from '../login-flow/two-factor-sms/TwoFactorSMS.page';
import TwoFactorTOTPPage from '../login-flow/two-factor-totp/TwoFactorTOTP.page';
import TwoFactorWebAuthnPage from '../login-flow/two-factor-webauthn/TwoFactorWebAuthn.page';
import PasswordResetPage from '../login-flow/password-reset/PasswordReset.page';
import SuccessPage from '../login-flow/success/Success.page';
import NotFound404Page from '../not-found-404/NotFound404.page';
import LogoutPage from '../logout/Logout.page';
import KillSwitchPage from '../kill-switch/KillSwitch.page';

import CardWithStepper from './card/CardWithStepper';

import { useFetchGraphQL } from './apiFetchOptions';
import { updateOrgProfile, orgProfileQuery } from '../store/orgProfileStore';
import { getLoggedInOrgProfile } from '../store/loggedInOrgProfileStore';
import useOrgProfile from './hooks/useOrgProfile';
import ProviderStack from './ProviderStack';
import UserSelectionPage from '../login-flow/user-selection/UserSelection.page';

const Routes = () => {
  // Skip all the org profile stuff on logout
  const location = useLocation();
  if (location.pathname === '/logout') {
    return (
      <Switch>
        <Route path="/logout" exact>
          <LogoutPage />
        </Route>
      </Switch>
    );
  }

  const scope = '/:httpsOption?/:lastwallUrl?';

  const history = useHistory();
  const { actions, state } = useStateMachine({
    updateOrgProfile,
  }) as any;

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const queryEntries = Object.fromEntries(query.entries());
  if (queryEntries.authSessionSlug) {
    state.authSessionSlug = queryEntries.authSessionSlug;
  }
  const orgProfile = useOrgProfile(state);

  // Org profiles are only loaded on the first page load - see the useEffect() after this
  // useFetchGraphQL().
  //
  // The routes on this page wait to render until the orgProfile is loaded.
  const orgProfileFetch: any = useFetchGraphQL(async ({ fetchGraphQL }) => {
    const data = await fetchGraphQL({
      query: orgProfileQuery,
      variables: {
        input: {
          authSessionSlug: state.authSessionSlug,
        },
      },
      state: {
        orgProfile: {
          csrfToken: null,
        },
      },
    });

    actions.updateOrgProfile(data);

    // skip authentication if already logged in to this profile
    const loggedInOrgProfile = getLoggedInOrgProfile();
    const successURL = '/success';

    if (
      loggedInOrgProfile?.policyID === data.orgProfile.policyID
      && history.location.pathname !== successURL
    ) {
      history.replace(successURL);
    }
  }, []);

  useEffect(() => {
    if (orgProfile == null) {
      orgProfileFetch.run();
    }
  }, []);

  useEffect(() => {
    if (orgProfileFetch.error) {
      // eslint-disable-next-line no-console
      console.error(orgProfileFetch.error);
    }
  }, [orgProfileFetch]);

  if (orgProfileFetch.error) {
    return (
      <Typography color="error">
        {orgProfileFetch.error.message}
      </Typography>
    );
  }

  if (orgProfile == null) {
    return <div />;
  }

  return (
    <Switch>
      <Route path={`${scope}/TOC`} exact>
        <TermsAndConditionsPage />
      </Route>

      {/* Primary Factors */}
      <Route
        path={[`${scope}/password`, `${scope}/webauthn`]}
        render={({ match }) => (
          <CardWithStepper
            activeStep={match.path.endsWith('/password') ? 1 : 0}
            logoURL={state.orgProfile?.logoURL}
            showStepper={state.stage == null || state.stage.primary}
          >
            <Route
              path={`${scope}/:factor/username`}
              exact
              render={({ match: usernameRouteMatch }) => (
                <LoginPage key={usernameRouteMatch.params.factor} />
              )}
            />

            <Route path={`${scope}/password`} exact>
              <PasswordPage />
            </Route>
            <Route path={`${scope}/webauthn`} exact>
              <TwoFactorWebAuthnPage />
            </Route>
          </CardWithStepper>
        )}
      />

      {/* Secondary Factors */}

      <Route path={`${scope}/recovery-code`} exact>
        <TwoFactorRecoveryCodePage />
      </Route>

      <Route path={`${scope}/piv`} exact>
        <TwoFactorPIVPage />
      </Route>

      <Route path={`${scope}/sms`} exact>
        <TwoFactorSMSPage />
      </Route>

      <Route path={`${scope}/totp`} exact>
        <TwoFactorTOTPPage />
      </Route>

      <Route path={`${scope}/user-selection`} exact>
        <UserSelectionPage />
      </Route>

      {/* Setup */}

      <Route path={`${scope}/two-factor-setup`} exact>
        <TwoFactorSetupPage />
      </Route>

      <Route path={`${scope}/setup-sms`} exact>
        <SMSSetupPage />
      </Route>

      <Route path={`${scope}/setup-totp`} exact>
        <TOTPSetupPage />
      </Route>

      <Route path={`${scope}/setup-piv`} exact>
        <PIVSetupPage />
      </Route>

      <Route path={`${scope}/setup-webauthn`} exact>
        <WebauthnSetupPage />
      </Route>

      <Route path={`${scope}/setup-recovery-code`} exact>
        <RecoveryCodeSetupPage />
      </Route>

      {/* Etc */}

      <Route path={'/friction'}>
        <FrictionPage />
      </Route>

      <Route path={`${scope}/password-reset`} exact>
        <PasswordResetPage />
      </Route>

      <Route path={`${scope}/logout`} exact>
        <LogoutPage />
      </Route>

      <Route path={`${scope}/success`} exact>
        <SuccessPage />
      </Route>

      <Route path="*">
        <NotFound404Page />
      </Route>
    </Switch>
  );
};

export const nonAuthSessionRoutes = () => [
  (
    <Route path="/kill/:authSessionSlug/:killSwitchToken" key="killSwitch" exact>
      <ProviderStack>
        <KillSwitchPage />
      </ProviderStack>
    </Route>
  ),
  (
    <Route path="/:serviceID/password-reset-not-allowed" key="no-reset" exact>
      <ProviderStack>
        <Typography>
          Password reset not alowed. Please contact your service administrator.
        </Typography>
      </ProviderStack>
    </Route>
  ),
];

export default Routes;
