import { useCallback } from 'react';
import useAsync from './useAsync';

export const apiPostOptions = (state): RequestInit => {
  if (state == null) {
    throw new Error('State cannot be null');
  }

  const { csrfToken } = state.orgProfile || {};
  // console.log({ state, csrfToken })

  const headers: any = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  if (csrfToken) {
    headers.Authorization = `Bearer ${csrfToken}`;
  }

  return {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    headers,
  };
};

export const graphqlURL = () => {
  let ssoServer = process.env.SSO_DNS_URL;

  // @ts-ignore
  if (!window.isProduction) {
    ssoServer = `http://${location.hostname}:4001`;
  }

  return `${ssoServer}/graphql`;
};

export const fetchGraphQL = async ({
  query,
  variables = null,
  options = null,
  state = null,
  signal,
}) => {
  const res = await fetch(graphqlURL(), {
    ...(options || apiPostOptions(state)),
    signal,
    body: JSON.stringify({
      query,
      variables,
    }),
  });

  let json;
  try {
    json = await res.json();
    // eslint-disable-next-line
  } finally {}

  if (!res.ok || json?.errors) {
    const message = json?.errors[0]?.message || 'Network Error. Please try again.';
    const error = new Error(message);

    // eslint-disable-next-line
    console.error(json?.errors[0] || error)

    throw error;
  }

  return json.data;
};

export const useFetchGraphQL = (cb, inputs) => {
  const asyncHook = useAsync({
    // eslint-disable-next-line @typescript-eslint/require-await
    deferFn: useCallback(async (args, { signal }) => {
      try {
        return cb({
          fetchGraphQL: (opts) => fetchGraphQL({
            signal,
            ...opts,
          }),
          signal,
          args,
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        throw error;
      }
    }, inputs),
  });

  return asyncHook;
};
