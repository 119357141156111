import React from 'react';
import { useStateMachine } from 'little-state-machine';

import LogoutView from './Logout.view';

const LogoutPage = () => {
  const { state } = useStateMachine() as any;

  return (
    <LogoutView
      serviceSignInURL={state.orgProfile.serviceSignInURL}
    />
  );
};

export default LogoutPage;
