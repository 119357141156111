import React from 'react';

import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';

import useStyles from './Logout.styles';

const LogoutView = ({
  serviceSignInURL,
}) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <Typography variant="h1" paragraph className={classes.title}>
        <Icon fontSize="inherit" className={classes.icon}>
          verified_user
        </Icon>
        Lastwall
      </Typography>
      <Typography variant="h2" paragraph>
        You have been successfully logged out
      </Typography>
      { serviceSignInURL && (
        <Button
          className={classes.button}
          color="primary"
          component="a"
          href={serviceSignInURL}
        >
          Log in again
        </Button>
      )}
    </Card>
  );
};

export default LogoutView;
