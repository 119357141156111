const useOrgProfile = (state) => {
  const authSessionSlug = state.authSessionSlug;
  
  const orgProfileReady = (
    state.orgProfile && state.orgProfile.loaded && (
      authSessionSlug == null
      || (
        state.orgProfile.csrfToken != null
        && state.orgProfile.authSessionSlug === authSessionSlug
      )
    )
  );

  if (orgProfileReady) {
    return state.orgProfile;
  }

  return null;
};

export default useOrgProfile;
