import 'core-js';
import 'react-app-polyfill/ie11';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';

import React from 'react';
import ReactDOM from 'react-dom';

import {
  StateMachineProvider,
  createStore,
  // DevTool,
} from 'little-state-machine';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

import ProviderStack from './shared/ProviderStack';
import Routes, { nonAuthSessionRoutes } from './shared/Routes';
import NotFound404Page from './not-found-404/NotFound404.page';
import ErrorPage from './error/Error.page';

import login from './store/loginStore';
import orgProfile from './store/orgProfileStore';
import stage from './store/stage/stageStore';

// @ts-ignore
window.isProduction = process.env.NODE_ENV === 'production';

const App = () => {
  createStore({
    login,
    orgProfile,
    stage,
  });

  return (
    <StateMachineProvider>
      <Router>
        <Switch>
          {nonAuthSessionRoutes()}
          <Route path="/">
            <ProviderStack>
              <ErrorBoundary fallbackRender={ErrorPage as any}>
                {/* {!window.isProduction && <DevTool />} */}
                <Routes />
              </ErrorBoundary>
            </ProviderStack>
          </Route>
          <Route path="*">
            <ProviderStack>
              <NotFound404Page />
            </ProviderStack>
          </Route>
        </Switch>
      </Router>
    </StateMachineProvider>
  );
};

ReactDOM.render(<App />, document.getElementById('app'));
