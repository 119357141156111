import React from 'react';

import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';

const ErrorPage = ({ error }) => {
  // eslint-disable-next-line no-console
  console.error(error);

  return (
    <Card style={{ padding: 16 }}>
      <Typography color="error" variant="h6">
        Something has gone wrong:
      </Typography>
      <Typography variant="body1">
        {error.message}
      </Typography>
    </Card>
  );
};

export default ErrorPage;
