const stageQueryFragment = `
  stage {
    primary
    state
    forceOnboarding
    numRequired
    enforceLoginFriction
    userLoginNameEntered
    nextPolicyRequirements {
      type
      verified
    }
    credentialSummaries {
      id
      type
      redactedDescriptor
    }
    factors {
      id
      type
      default
      passed
      failed
      extras
    }
    factorAuthorizationTokens {
      id
      username
    }
  }
`;

export default stageQueryFragment;
