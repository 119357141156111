import { makeStyles } from '@mui/styles';
import { loginAndPasswordCommonStyles } from '../login/Login.styles';

// @ts-ignore
export default makeStyles((theme) => ({
  ...loginAndPasswordCommonStyles(theme),

  hidePassword: {
    cursor: 'pointer',
  },
}));
