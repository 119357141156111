import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  card: {
    display: 'flex',
    width: 400,
    [theme.breakpoints.only('xs')]: {
      // 360px is the smallest viewport width of popular Android phones atm
      width: 360,
    },
    flexDirection: 'column',
    justifyContent: 'center',
    flexShrink: 0,
  },
  moreMenu: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  logo: {
    height: 160,
    backgroundSize: 'contain',
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(5),
  },
}));
