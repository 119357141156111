import React, { useEffect } from 'react';
import { useStateMachine } from 'little-state-machine';

import TermsAndConditionsView from './TermsAndConditions.view';

import { updateLogin } from '../../store/loginStore';
import { updateStage } from '../../store/stage/stageStore';
import useNavigateToNext from '../../shared/hooks/useNavigateToNext';
import { useLocation } from 'react-router-dom';

const TermsAndConditionsPage = () => {
  const { state } = useStateMachine({
    updateLogin,
    updateStage,
  }) as any;

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const queryEntries = Object.fromEntries(query.entries());
  if (queryEntries.authSessionSlug) {
    state.authSessionSlug = queryEntries.authSessionSlug;
  }
  
  const showTerms = state.orgProfile.enableTermsAndConditions;

  const navigateToNext = useNavigateToNext();

  const navigateToFirstFactor = () => navigateToNext({ stage: state.stage });

  useEffect(() => {
    if (!showTerms) {
      navigateToFirstFactor();
    }
  }, [showTerms]);

  const {
    termsAndConditionsMarkdown,
    termsAndConditionsAcceptButtonText,
  } = state.orgProfile;

  return (
    <TermsAndConditionsView {...{
      logoURL: state.orgProfile.logoURL,
      acceptTerms: () => navigateToFirstFactor(),
      termsAndConditionsMarkdown,
      termsAndConditionsAcceptButtonText,
    }}
    />
  );
};

export default TermsAndConditionsPage;
