import React, { createRef, useEffect, useLayoutEffect } from 'react';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

// import { useSnackbar } from 'notistack'
import { useForm } from 'react-hook-form';
import FadeIn from 'react-fade-in';

import { useStateMachine } from 'little-state-machine';

import useStyles from './Login.styles';
import { useRISCInputRef } from '../../shared/risc/RISCContext';
import TwoFactorSelector from '../two-factor-selector/TwoFactorSelector';
import factorsDisplayText from '../../shared/factorsDisplayText';

const LoginView = ({
  error: externalError,
  onSubmit,
  isSubmitting = false,
  factors,
  factorType,
}) => {
  const classes: any = useStyles();
  // const snackbar = useSnackbar()

  const { state } = useStateMachine() as any;

  const ua = navigator.userAgent;
  const isPanGlobalProtect = ua.toLowerCase().includes('globalprotect');

  const currentFactor = factorsDisplayText[factorType.toUpperCase()];

  const usernameRef: any = createRef();
  useRISCInputRef(usernameRef, true);
  useLayoutEffect(() => {
    usernameRef.current.focus();
  }, []);

  const {
    handleSubmit,
    register,
    errors,
    setError,
    formState,
  } = useForm();

  const error = errors.username?.message;

  useEffect(() => {
    if (!isSubmitting) {
      setError('username', {
        type: 'server',
        message: externalError,
        shouldFocus: true,
      });
    }
  }, [externalError, isSubmitting]);

  return (
    <form
      className={classes.formContainer}
      // @ts-ignore
      onSubmit={handleSubmit((values) => onSubmit({ values, setError }))}
    >
      <FadeIn delay={100} transitionDuration={500}>
        <Typography variant="h2" className={classes.title}>
          Sign in using&nbsp;
          {currentFactor.title}
        </Typography>
        <TextField
          defaultValue={state.login.username}
          label="Enter your email"
          name="username"
          variant="outlined"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            autoComplete: 'off',
            autoFocus: true,
            endAdornment: isPanGlobalProtect ? undefined : (
              <InputAdornment position="end">
                <i className="material-icons">account_circle</i>
              </InputAdornment>
            ),
          }}
          inputRef={(e) => {
            usernameRef.current = e;
            register(e, {
              required: 'Required',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: 'Invalid email address',
              },
            });
          }}
          error={error != null}
          helperText={error}
        />

        <Typography variant="body2" className={classes.credentialsInstructions}>
          Always keep your login credentials secure
        </Typography>

        <div className={classes.actions}>
          {/* <Button
            variant="outlined"
            onClick={() => {
              snackbar.enqueueSnackbar('Account recovery has not been enabled for this domain')
            }}
          >
            Recover Account
          </Button> */}
          <TwoFactorSelector
            factors={factors.filter((x) => x.type.toLowerCase() !== factorType)}
          />
          <Button
            className={classes.loginButton}
            color="primary"
            type="submit"
            variant="contained"
            disabled={isSubmitting || formState.isSubmitting}
          >
            Next
          </Button>
        </div>
      </FadeIn>
    </form>
  );
};

export default LoginView;
