import 'regenerator-runtime/runtime';

import React, { Suspense } from 'react';
import { StylesProvider } from '@mui/styles';
import { SnackbarProvider } from 'notistack';

import LastWallThemeProvider from './theme/LastWallThemeProvider';
import { RISCProvider } from './risc/RISCContext';
import Centered from './card/Centered';

const ProviderStack = ({ children }) => (
  <LastWallThemeProvider>
    <StylesProvider injectFirst>
      <RISCProvider>
        <SnackbarProvider>
          <Suspense fallback={<div />}>
            <Centered>
              {children}
            </Centered>
          </Suspense>
        </SnackbarProvider>
      </RISCProvider>
    </StylesProvider>
  </LastWallThemeProvider>
);

export default ProviderStack;
