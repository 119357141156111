import React from 'react';
import { useStateMachine } from 'little-state-machine';

import TwoFactorRecoveryCodeView from './TwoFactorRecoveryCode.view';
import useNavigateToNext from '../../shared/hooks/useNavigateToNext';
import findFactor from '../../store/stage/findFactor';
import { useFetchGraphQL } from '../../shared/apiFetchOptions';
import stageQueryFragment from '../../store/stage/stageQuery.fragment';

const TwoFactorRecoveryCodePage = () => {
  const navigateToNext = useNavigateToNext();

  const { state } = useStateMachine() as any;
  const { factors } = state.stage;
  const factor = findFactor(state, 'RECOVERY_CODE');

  const tryRecoveryCodeMutation = `
    mutation($input: TryRecoveryCodeInput!) {
      tryRecoveryCode(input: $input) {
        ${stageQueryFragment}
      }
    }
  `;

  const tryRecoveryCode: any = useFetchGraphQL(async ({ args, fetchGraphQL }) => {
    const { values } = args[0];

    const data = await fetchGraphQL({
      state,
      query: tryRecoveryCodeMutation,
      variables: {
        input: {
          ...values,
        },
      },
    });

    navigateToNext(data.tryRecoveryCode);
  }, [state]);

  return (
    <TwoFactorRecoveryCodeView {...{
      factors,
      failed: factor.failed,
      onSubmit: tryRecoveryCode.run,
      isSubmitting: tryRecoveryCode.isPending,
      error: tryRecoveryCode.error?.message,
    }}
    />
  );
};

export default TwoFactorRecoveryCodePage;
